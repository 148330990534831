/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 29번째 로그에서는 파이어폭스 애드온 비활성화 장애, GitHub/GitLab 저장소 삭제 공격, MS 빌드 2019 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.mozilla.org/addons/2019/05/04/update-regarding-add-ons-in-firefox/"
  }, "Add-ons disabled or failing to install in Firefox | Mozilla Add-ons Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://weblog.rubyonrails.org/2019/4/24/Rails-6-0-rc1-released/"
  }, "Rails 6.0.0 rc1 released | Riding Rails")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--vs-code-now-supports-remote-development"
  }, "마이크로소프트 비주얼 스튜디오 코드(Visual Studio Code), 원격 개발 기능 지원 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://code.visualstudio.com/insiders/"
  }, "Download Visual Studio Code Insiders")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--amazon-s3-will-no-longer-support-path-style-API-requests"
  }, "아마존 S3(Amazon S3), 2020년 9월 경로(path) 형식의 API 호출 중지 예정 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.rook.io/rook-v1-0-a-major-milestone-689ca4c75508"
  }, "Rook v1.0 — A Major Milestone – Rook Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--ms-build-2019-introducing-windows-terminal"
  }, "마이크로소프트 빌드 2019, 윈도우 터미널 발표 - 파워셸, 커맨드 프롬프트 WSL 지원 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/piroor/treestyletab"
  }, "piroor/treestyletab: Tree Style Tab, Show tabs like a tree.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://addons.mozilla.org/ko/firefox/addon/tree-style-tab/"
  }, "Tree Style Tab – 🦊 Firefox 용 확장기능(ko)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://direct.samsungfire.com/"
  }, "대한민국 1등 다이렉트 - 삼성화재 다이렉트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jobis.co/"
  }, "인공지능 경리 - 자비스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://robert.ocallahan.org/2014/08/choose-firefox-now-or-later-you-wont.html"
  }, "Eyes Above The Waves: Choose Firefox Now, Or Later You Won't Get A Choice")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://imgur.com/a/Un1FWv4"
  }, "Firefox? Really? - Album on Imgur")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bleepingcomputer.com/news/security/attackers-wiping-github-and-gitlab-repos-leave-ransom-notes/"
  }, "Attackers Wiping GitHub and GitLab Repos, Leave Ransom Notes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.quora.com/How-do-hackers-scan-AWS-keys-on-GitHub"
  }, "How do hackers scan AWS keys on GitHub? - Quora")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://help.github.com/en/articles/securing-your-account-with-two-factor-authentication-2fa"
  }, "Securing your account with two-factor authentication (2FA) - GitHub Help")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.apple.com/en-us/HT204915"
  }, "Two-factor authentication for Apple ID - Apple Support")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.microsoft.com/build2019/"
  }, "Microsoft Build 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://events.google.com/io/"
  }, "Google I/O 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=LoLqSbV1ELU"
  }, "Developer Keynote (Google I/O '19) - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ytn.co.kr/_ln/0103_201905090700063965"
  }, "사회 - 박막례 할머니, 유튜브 CEO 이어 구글 CEO도 만났다 | YTN")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/commandline/introducing-windows-terminal/"
  }, "Introducing Windows Terminal | Windows Command Line Tools For Developers")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/Cmd.exe"
  }, "cmd.exe - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%9C%88%EB%8F%84%EC%9A%B0_%ED%8C%8C%EC%9B%8C%EC%85%B8"
  }, "윈도우 파워셸 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%A6%AC%EB%88%85%EC%8A%A4%EC%9A%A9_%EC%9C%88%EB%8F%84%EC%9A%B0_%ED%95%98%EC%9C%84_%EC%8B%9C%EC%8A%A4%ED%85%9C"
  }, "리눅스용 윈도우 하위 시스템 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/commandline/announcing-wsl-2/"
  }, "Announcing WSL 2 | Windows Command Line Tools For Developers")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/microsoft/Terminal"
  }, "microsoft/Terminal: The new Windows Terminal, and the original Windows console host -- all in the same place!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/visualstudio/intelligent-productivity-and-collaboration-from-anywhere/"
  }, "Intelligent Productivity and Collaboration, from Anywhere | The Visual Studio Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/Microsoft/vscode-python/issues/79"
  }, "RFE: Support Remote Interpreter · Issue #79 · microsoft/vscode-python")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jetbrains.com/help/pycharm/using-docker-as-a-remote-interpreter.html"
  }, "Configure a remote interpreter using Docker - Help | PyCharm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://azure.microsoft.com/ko-kr/"
  }, "Microsoft Azure 클라우드 컴퓨팅 플랫폼 및 서비스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.microsoft.com/en-us/azure/terraform/terraform-cloud-shell"
  }, "Use Terraform with Azure Cloud Shell | Microsoft Docs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/2019-05-06-team-synchronization-across-github-and-azure-active-directory/"
  }, "Team synchronization across GitHub and Azure Active Directory - The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://visualstudio.microsoft.com/subscriptions/visual-studio-github/"
  }, "Visual Studio with GitHub Enterprise - Visual Studio")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://msdn.microsoft.com/ko-kr/"
  }, "Microsoft Developer Network에서 개발하는 방법 알아보기 | MSDN")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.akamai.com/kr/ko/"
  }, "보안, 클라우드 전송, 웹 성능 | 아카마이 테크놀로지스 KR")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://electronjs.org/"
  }, "Electron | 자바스크립트, HTML, CSS를 이용해 크로스 플랫폼 데스크톱 앱을 만들어보세요.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://coder.com/"
  }, "Coder is an online IDE serving VSCode.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ide.goorm.io/"
  }, "구름IDE - 설치가 필요없는 통합개발환경 서비스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/Crossing-Chasm-3rd-Disruptive-Mainstream-ebook/dp/B00DB3D81G"
  }, "Amazon.com: Crossing the Chasm, 3rd Edition: Marketing and Selling Disruptive Products to Mainstream Customers (Collins Business Essentials) eBook: Geoffrey A. Moore: Kindle Store")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://9to5google.com/2019/05/07/cancelling-galaxy-fold-orders/"
  }, "Galaxy Fold orders will be automatically canceled by default- 9to5Google")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/business/view/2019/02/112284/"
  }, "붙이면 화면 2배 듀얼스크린 LG전자 V50로 5G 선점 - 매일경제")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
